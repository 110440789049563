<script setup>
import { onLaunch, onShow, onError, onLoad } from '@dcloudio/uni-app';
import { ShoproInit } from './sheep';
import AreaApi from '@/sheep/api/system/area';
import AuthUtil from '@/sheep/api/member/auth';
import HomeApi from '@/sheep/api/home/home';
import sheep from '@/sheep';
import Aegis from 'aegis-web-sdk';
import couponsApi from '@/sheep/api/coupons/coupons';
import eruda from 'eruda';
import { GetWhitePages } from './utils/visitorMode';

var baseUrl = import.meta.env.SHOPRO_BASE_URL;
const activities = [
  {
    old:'https://dev-h5.fushangyunfu.com/pages/activeArea/index?activityId=24',
    new:'https://000024.dev-h5.fushangyunfu.com/pages/index/index'
  },
  {
    old:'https://dev-h5.fushangyunfu.com/pages/activeArea/index?activityId=21',
    new:'https://000021.dev-h5.fushangyunfu.com/pages/index/index'
  },
  {
    old:'https://dev-h5.fushangyunfu.com/pages/activeArea/index?activityId=18',
    new:'https://000018.dev-h5.fushangyunfu.com/pages/index/index'
  },
  {
    old:'https://dev-h5.fushangyunfu.com/pages/activeArea/index?activityId=10',
    new:'https://000010.dev-h5.fushangyunfu.com/pages/index/index'
  },
  {
    old:'https://dev-h5.fushangyunfu.com/pages/activeArea/index?activityId=8',
    new:'https://000008.dev-h5.fushangyunfu.com/pages/index/index'
  }
]

onLaunch(async (options) => {
  // activities.forEach(async item => {
  //   if(item.old == window.location.href){
  //     location.href = item.new
  //   }
  // })

  
  // if(window.location.href.indexOf('temporaryToken') != -1){
  //   // 从链接中获取到temporaryToken的值
  //   const temporaryToken = window.location.href.split('temporaryToken=')[1].split('&')[0]
  //   const res = await AuthUtil.getTempToken({temporaryToken: temporaryToken})
  //   uni.setStorageSync('token',res.data.accessToken)
  //   uni.setStorageSync('refreshToken',res.data.refreshToken)
  //   uni.setStorageSync('userId',res.data.userId)
  // }


  if(!!options.query.activityId){
    uni.setStorageSync("activityId", options.query.activityId);
    uni.setStorageSync("activityHomeUrl", window.location.pathname);
  }
  if(!!options.query.isHeavy){
    uni.setStorageSync("isHeavy", options.query.isHeavy);
  }
  if (!!uni.getStorageSync('areaData')) {
  } else {
    // 提前加载省市区数据
    AreaApi.getAreaTree().then((res) => {
      if (res.code === 0) {
        uni.setStorageSync('areaData', res.data);
      }
    });
  }
  if (baseUrl === 'https://safe.api.fushangyunfu.com' ) {
    const aegis = new Aegis({
      id: 'xEj7ltQ5LkVK0l8K7Y', // 应用ID，即上报ID
      uin: '', //用户唯一 ID（可选）
      reportApiSpeed: true, //接口测速
      reportAssetSpeed: true, // 静态资源测速
      hostUrl: 'https://aegis.qq.com', // 上报域名，中国大陆 rumt-zh.com
      spa: true, //spa 应用页面跳转的时候开启 pv 计算
      api: {
        apiDetail: true,
      },
    });
    let userId = uni.getStorageSync('userId') || '';
    if (userId) {
      aegis.setConfig({
        uin: userId,
      });
    }
    const getUserIdSet = setInterval(() => {
      if (userId) {
        aegis.setConfig({
          uin: userId,
        });
        clearInterval(getUserIdSet);
      } else {
        userId = uni.getStorageSync('userId') || '';
      }
    }, 5000);
  }
  saveFirstPage();
  ShoproInit();

  // 加载Shopro底层依赖

  enterPageCheck();
  getUserInfo()
  
  if(window.location.href.indexOf('erudaType=eruda')>-1){
    sessionStorage.setItem('erudaType','eruda')
  }
  let isShowErude = true
  if(isShowErude && sessionStorage.getItem('erudaType')) {
    isShowErude = false
    eruda.init()
  }
});

const enterPageCheck = async () => {
  if(!uni.getStorageSync('token') && !uni.getStorageSync('refresh-token')){
    sheep.$store("user").resetUserData();
  }
  
  let locationHref = window.location.href;
  let isWhitePage = false;
  const { data } = await AuthUtil.getUserTypeObj('tourist.flag') 
  const openVisitorMode = data === 'false'? false : true
  uni.setStorageSync('openVisitorMode', openVisitorMode)
  if (locationHref.includes('pages')) {
      const arr = GetWhitePages(openVisitorMode);
      arr.map((item) => {
        if (locationHref.includes(item)) {
          isWhitePage = true;
          return;
        }
      });
    }
    else { //首页
      if(openVisitorMode) isWhitePage = true;
      else {
        if(uni.getStorageSync('token')) isWhitePage = true;
        else isWhitePage = false;
      }
    }
  // 判断登录
  // console.log(uni.getStorageSync('refresh-token'), '555', !uni.getStorageSync('token'));
  if (uni.getStorageSync('refresh-token') && !uni.getStorageSync('token')) {
    const refreshTokenResult = await AuthUtil.refreshToken(uni.getStorageSync('refresh-token'));
    if(refreshTokenResult.code === 0 && locationHref.indexOf('/pages/author/index') > -1){
      sheep.$router.redirect('/pages/index/index');
    }
    if (refreshTokenResult.code !== 0 && !isWhitePage) {
      uni.removeStorageSync('refresh-token');
      sheep.$router.redirect('/pages/author/index');
      throw new Error('刷新令牌失败');
    }
  }
  if (!isWhitePage) {
    if (!uni.getStorageSync('token')) {
      sheep.$router.redirect('/pages/author/index');
    } else {
      couponsApi.checkCoupons().then((res) => {
        if (res.data) {
          couponsApi.checkCheckSign().then((e) => {
            if(e.data){
              sheep.$router.redirect('/pages/yfcoupon/couponlist/couponSign');
              return;
            }
          })
          //判断是否绑卡
          // uni.switchTab({ url: '/pages/index/index' })//绑卡了可以过
        } else {
          if (res.code == 0&&window.location.href.indexOf('/pages/yfcoupon/addcoupon/addcoupon') === -1) {
            window.location.href = window.location.origin +'/pages/yfcoupon/addcoupon/addcoupon?isindex=1'
          }
        }
      });
    }
  }
  if(uni.getStorageSync('token') && isWhitePage){
    couponsApi.checkCoupons().then((res) => {
        if (res.data) {
          //判断是否绑卡
          couponsApi.checkCheckSign().then((e) => {
            if(e.data){
              sheep.$router.redirect('/pages/yfcoupon/couponlist/couponSign');
              return;
            }
          })
          // uni.switchTab({ url: '/pages/index/index' })//绑卡了可以过
        } else {
          if (res.code == 0&&window.location.href.indexOf('/pages/yfcoupon/addcoupon/addcoupon') === -1) {
            window.location.href = window.location.origin +'/pages/yfcoupon/addcoupon/addcoupon?isindex=1'
          }
        }
      });
  }
};
function saveFirstPage() {
  let locationHref = window.location.href;
  if(locationHref.indexOf('pages/author/index') === -1&&locationHref.indexOf('pages/yfcoupon/addcoupon/addcoupon') === -1
    && locationHref.indexOf('pages/yfcoupon/couponlist/couponSign') === -1
  ){
    sessionStorage.setItem('entryUrl', locationHref);
    uni.setStorageSync('redirectUrl', locationHref)
  }
}

async function getUserInfo() {
	if (!uni.getStorageSync('token')) {
		return
	}
	sheep.$store('user').getInfo()
}

const getUserIdSet = () => {};
onError((err) => {
  // console.log('AppOnError:', err);
});

onShow((options) => {
  // #ifdef APP-PLUS
  // 获取urlSchemes参数
  const args = plus.runtime.arguments;
  if (args) {
  }

  // 获取剪贴板
  uni.getClipboardData({
    success: (res) => {},
  });
  // #endif

  // #ifdef MP-WEIXIN
  // 确认收货回调结果
  // #endif
});
</script>

<style lang="scss">
@import "uview-ui/index.scss";
// 引入vant-weapp样式
@import "@/sheep/scss/index.scss";


</style>
<style lang="scss" >
// #ifdef  MP-WEIXIN
/* @import '/wxcomponents/@vant/weapp/common/index.wxss'; */
// #endif
@import "@/components/css/page.css";
.uni-tabbar {
  display: none;
}
uni-modal{
  z-index: 10075;
}

.alarmInfo{
  width: 100%;
	padding: 0 20rpx;
	box-sizing: border-box;
	height: 48rpx;
	display: flex;
	align-items: center;
	background-color: #FEF7E4;
	color: #EEB46C;
	font-size: 24rpx;
	font-weight: 600;
	z-index: 981;
  
}
.virtualInfo{
  width: 100%;
	padding: 0 20rpx;
	box-sizing: border-box;
	height: 48rpx;
	display: flex;
	align-items: center;
  justify-content: space-between;
	background-color: #FEF7E4;
	color: #EEB46C;
	font-size: 24rpx;
	font-weight: 600;
	z-index: 981;
  
}
.virtualInfo .left{
  display: flex;
  align-items: center;
}

.zhezhao{
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba( 0,0,0,0.6);
  z-index: 2001;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .tcImg{
    width: 100%;
    height: auto;
  }
  .zheZaoBtn{
    margin-top: 30rpx;
    width: 72rpx;
    height: 72rpx;
  }
}
.WPH{
  background: linear-gradient( 137deg, #FF74A2 0%, #FF6095 22%, #FF377A 100%) !important;
}
.TM{
  background: #00CD00 !important;
}  
</style>
